const routes = require('next-routes')

const Routes = routes()
    .add('index', '/', 'index')
    .add('login', '/login', 'login')
    .add('companies', '/companies', 'companies')
    .add('users', '/users', 'users')
    .add('company-create', '/companies/create', 'company-create')
    .add('company-edit', '/companies/:uuid/edit/:type', 'company-edit')
    .add('company', '/companies/:uuid', 'company')
    .add('categories', '/categories', 'categories')
    .add('features', '/features', 'features')
    .add('franchisee', '/franchisee', 'franchisee')
    .add('franchisee-create', '/franchisee/create', 'franchisee-create')
    .add('franchisee-edit', '/franchisee/:uuid', 'franchisee-edit')
    .add('franchisee-report', '/reports/franchisee', 'franchisee-report')
    .add('integrations', '/integrations', 'integrations')
    .add('content', '/settings-app/content', 'content')
    .add('content-create', '/settings-app/content/create', 'content-create')
    .add('content-edit', '/settings-app/content/:uuid', 'content-edit')
    .add('banners', '/settings-app/banners', 'banners')
    .add('blocks', '/settings-app/blocks', 'blocks')
    .add('app-versions', '/settings-app/versions', 'app-versions')
    .add('promocodes', '/promocodes', 'promocodes')
    .add('newsletters-active', '/newsletters/active', 'newsletters-active')
    .add('newsletters-inactive', '/newsletters/inactive', 'newsletters-inactive')
    .add('newsletters-create', '/newsletters/create', 'newsletters-create')
    .add('newsletters-tasks', '/newsletters/tasks', 'newsletters-tasks')
    .add('newsletters-edit', '/newsletters/:uuid', 'newsletters-edit')

module.exports = Routes

exports.Router = Routes.Router
exports.Link = Routes.Link
